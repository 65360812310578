import React from "react"

import Layout from "../components/layout"
import Header from "../components/header"
import HeroUI from "../components/hero-ui"
import Section from "../components/section"
import Carousel from "../components/carousel"
import FinalCTA from "../components/final-cta"
import SEO from "../components/seo"
import { ImageLogo } from "../components/gatsby-image/host-logos"

const Businesses = () => (
  <Layout>
    <Header menuItems={menuItems} />
    <HeroUI
      src="ok"
      heading="Free apprenticeship training"
      description="Hundreds of businesses trust us with their training. ATNZ hires apprentices then contracts them to companies, so you get the worker and we get the paperwork."
      buttonText="Find an apprentice"
    />
    <SEO
      title="Businesses"
      description="There's never been a better time to host an apprentice. The NZ Government has injected $1.6 billion into vocational education. We handle the business admin and you get the apprentice."
    />
    {sectionItems.map(
      ({
        heading,
        bgColor,
        textColor,
        columnPlacement,
        secondaryIdeas,
        src,
        description,
        microheading,
        microheadingColor,
      }) => (
        <Section
          heading={heading}
          description={description}
          bgColor={bgColor}
          textColor={textColor}
          columnPlacement={columnPlacement}
          secondaryIdeas={secondaryIdeas}
          src={src}
          microheading={microheading}
          microheadingColor={microheadingColor}
        />
      )
    )}
    <SuccessStories />
    <FinalCTA
      description="See if ATNZ is right for your business. No commitment, let’s start the conversation."
      buttonText="Connect with an advisor"
    />
  </Layout>
)

export default Businesses

const sectionItems = [
  {
    microheading: "Why now?",
    microheadingColor: "#074B64",
    heading: "There's never been a better time to host an apprentice",
    description:
      "The NZ Government has injected $1.6 billion into vocational education",
    bgColor: "white",
    src: "thumbsUp",
    columnPlacement: 1,
    secondaryIdeas: [
      {
        icon: "apprenticeship-boost",
        heading: "Government subsidies",
        bodyText:
          "A monthly payment for employers of first and second year apprentices to help employers keep existing apprentices and take on new ones.",
      },
      {
        icon: "no-apprenticeship-fees",
        heading: "No apprenticeship fees",
        bodyText:
          "All ATNZ apprenticeships are fees-free unti 31 December 2022, thanks to the Targeted Training and Apprenticeship Fund (TTAF).",
      },
      {
        icon: "no-atnz-fees",
        heading: "No ATNZ management fees",
        bodyText:
          "Our account management support is also now free until June 2021, thanks to the Government’s Group Training Scheme funding.",
      },
    ],
  },
  {
    microheading: "Why ATNZ?",
    microheadingColor: "#ebbcb3",
    heading: "Get back to business",
    description:
      "ATNZ completely handles the admin and risks of hosting an apprentice.",
    bgColor: "#074B64",
    src: "touch",
    textColor: "white",
    columnPlacement: 2,
    secondaryIdeas: [
      {
        icon: "we-do-admin",
        heading: "We do the admin",
        bodyText:
          "ATNZ pays your apprentice’s          wages, Kiwisaver, ACC, holiday          pay, sick pay and statutory          holidays plus covers their wages          when they are away on block          courses. We manage any HR          issues that may arise too. ",
      },
      {
        icon: "prepare-staff",
        heading: "Preparing your worker",
        bodyText:
          "Our HR and Health and Safety          teams equip your apprentices          with the resources they need to          be job-ready from day one.",
      },
    ],
  },
  {
    microheading: "Why ATNZ",
    microheadingColor: "#074B64",
    heading: "NZ's most trusted training partner",
    description:
      "We’re the county’s largest employer of apprentices in mechanical engineering and related industries.",
    bgColor: "#FFE5E0",
    src: "heart",
    secondaryIdeas: [
      {
        icon: "the-best-support",
        heading: "The best support",
        bodyText:
          "Our account          managers provide           mentoring, coachin and          wrap-around support to each          apprentice with monthly visits to          check their progress, manage          assessments and customise          training.",
      },
      {
        icon: "no-hidden-cost",
        heading: "No hidden costs",
        bodyText:
          "You pay one simple hourly          rate that covers all the costs          of recruitment,          pre-employment checks          and induction, wages, and          off-job training.",
      },
    ],
  },
]

const SuccessStories = () => {
  return (
    <div className="margin success-stories">
      <p style={{ color: "#074B64" }}>
        <strong>Success stories</strong>
      </p>
      <Carousel>
        {stories.map(({ company, quote, logo, person, position }) => (
          <Story
            company={company}
            quote={quote}
            logo={logo}
            person={person}
            position={position}
          />
        ))}
      </Carousel>
    </div>
  )
}

const Story = ({ company, logo, quote, person, position }) => {
  return (
    <div className="story-card-cnt">
      <div className="story-card">
        <p className="quote">
          <span className="speech-mark">"</span>
          {quote}"
        </p>
        {/* <div className="image-cnt">
          <div className="image"></div>
        </div> */}
        <div className="details">
          <div>{person}</div>
          <div>{position}</div>

          <h3>{company}</h3>
          <div className="host-logo-cnt">
            <div className="host-logo">
              <ImageLogo logo={logo} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const stories = [
  {
    person: "Johno Williams",
    position: "Managing Director",
    company: "Patton Engineering",
    quote:
      "ATNZ take the hassle out of taking on an apprentice: everything is taken care of so we can focus on their training",
    logo: "patton",
  },
  {
    person: "Eddie Green",
    position: "Maintenance Superintendent",
    company: "Pacific Steel",
    quote:
      "We know ATNZ’s high level of support achieves excellent results, ultimately adding measurable value to our business. That’s why we’ve worked with them for over 18 years.",
    logo: "pacific",
  },
  {
    person: "Andrew Crompton",
    position: "Operations Manager",
    company: "Eastbridge Ltd",
    quote:
      "We’ve partnered with ATNZ for over 13 years because we know their high level of support gets better results for our apprentices and our business.",
    logo: "eastbridge",
  },
  {
    person: "Kaleb James",
    position: "Managing Director",
    company: "Stafford Engineering",
    quote:
      "The on-going support from our ATNZ Account Manager is outstanding. They act as a mentor, coach and friend to our apprentices.",
    logo: "stafford",
  },
  {
    person: "Rickie Pike",
    position: "Director",
    company: "We Can Precision Engineering",
    quote:
      "We’ve partnered with ATNZ for over 20 years because we get the best results for our apprentices and our business with them.",
    logo: "weCan",
  },
]

const menuItems = [
  {
    title: "About ATNZ",
    link: "https://atnz.org.nz/about-atnz/",
  },
  {
    title: "About hosting",
    link: "https://atnz.org.nz/host-an-apprentice/about-hosting/",
  },
  {
    title: "Contact us",
    link: "https://atnz.org.nz/about-atnz/contact-us/",
  },
]
